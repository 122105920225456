import React, { useEffect, useState } from "react";
import { Modal, Button, Alert } from "react-bootstrap";
import {
  useAccount,
  useWatchContractEvent,
  useContractWrite,
  useWaitForTransactionReceipt,
  useWriteContract,
  useReadContract,
} from "wagmi";
import { useGlobalState } from "../helpers/globalState";
import {
  filterMinted,
  getApprove,
  getBalance,
  getMetaDataFromNumber,
  getPlanetAttributes,
  getPlanetMintFee,
  getTokenBalance,
  removeDecimal,
  truncateEthereumAddress,
} from "../helpers/functions";
import { info } from "../info";
import { tokenAbi } from "../abi/tokenAbi";
import { planetAbi } from "../abi/planetAbi";
import starMetadata from "../assets/metadata/newstars.json";
import planetMetadata from "../assets/metadata/newplanets.json";
import { starAbi } from "../abi/starAbi";
import { erc20Abi } from "viem";
import { writeContract as writeMint } from "@wagmi/core";
import { config } from "../App";
import { sepolia } from "viem/chains";

function Minting({ show, setShow, mintToInventory }) {
  const { address, isConnected } = useAccount();
  const { globalState, setGlobalState } = useGlobalState();
  const [minted, setMinted] = useState(null);
  const [mintedId, setMintedId] = useState(null);
  const [screen, setScreen] = useState(0);
  const [minting, setMinting] = useState("planet");
  const [mintedMetadata, setmintedMetadata] = useState(null);
  const [isApproveEnoughForPlanet, setIsApproveEnoughForPlanet] =
    useState(true);
  const [isApproveEnoughForStar, setIsApproveEnoughForStar] = useState(false);
  const { writeContract } = useWriteContract();

  const [txCount, settxCount] = useState(0);

  useWatchContractEvent({
    address: info.planetContractAddress,
    abi: planetAbi,
    eventName: "Minted",
    chainId: sepolia.id,

    onLogs(log) {
      console.log(log);
      if (log[0].args.receiver.toUpperCase() == address.toUpperCase()) {
        const id = log[0].args.tokenId.toString();
        setMintedId(id);
        const meta = getMetaDataFromNumber(id, planetMetadata);
        setmintedMetadata(meta);
        setScreen((scr) => scr + 1);
      }
    },
  });

  useWatchContractEvent({
    address: info.starContractAddress,
    abi: starAbi,
    chainId: sepolia.id,
    eventName: "Minted",
    onLogs(log) {
      console.log(log);
      if (log[0].args.receiver.toUpperCase() == address.toUpperCase()) {
        const id = log[0].args.tokenId.toString();
        setMintedId(id);
        const meta = getMetaDataFromNumber(id, starMetadata);
        setmintedMetadata(meta);
        setScreen((scr) => scr + 1);
      }
    },
  });

  useEffect(() => {
    const settingMintedData = async () => {
      const attr = await getPlanetAttributes(mintedId);
      setMinted(attr);
    };
    if (String(mintedId)) {
      settingMintedData();
    }
  }, [mintedId]);

  const handleClose = () => {
    setShow(false);
    setScreen(0);
    setMinting("planet");
    setMinted(null);
    setMintedId(null);
  };

  const cosmotesApprovedPlanet = useReadContract({
    address: info.cosmotesContractAddress,
    abi: erc20Abi,
    functionName: "allowance",
    watch: true,
    args: [address, info.planetContractAddress],
    watch: true,
  });

  const cosmotesApprovedStar = useReadContract({
    address: info.cosmotesContractAddress,
    abi: erc20Abi,
    functionName: "allowance",
    watch: true,
    args: [address, info.starContractAddress],
    watch: true,
  });

  useEffect(() => {
    const getWalletValues = async () => {
      try {
        //cosmotes balance of user
        const cosmotesBalanceDecimal = await getTokenBalance(
          address,
          info.cosmotesContractAddress
        );

        const isEnough =
          Number(cosmotesApprovedPlanet.data.toString()) >=
          Number(globalState.planetFeeDecimal)
            ? true
            : false;

        setIsApproveEnoughForPlanet(isEnough);

        const isEnoughStar =
          Number(cosmotesApprovedStar.data.toString()) >=
          Number(globalState.starFeeDecimal)
            ? true
            : false;

        setIsApproveEnoughForStar(isEnoughStar);
      } catch (error) {
        console.log(error);
      }
    };
    if (address && isConnected) {
      getWalletValues();
    }
  }, [address, isConnected, txCount, globalState?.planetFeeDecimal]);

  const mintPlanetSend = async () => {
    try {
      setMinting("planet");
      const result = await writeMint(config, {
        address: info.planetContractAddress,
        abi: planetAbi,
        functionName: "mintToken",
      });

      if (result) {
        setScreen((sc) => sc + 1);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const mintStarSend = async () => {
    try {
      setMinting("star");
      const result = await writeMint(config, {
        address: info.starContractAddress,
        abi: planetAbi,
        functionName: "mintToken",
      });
      console.log(result);
      if (result) {
        setScreen((sc) => sc + 1);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      {address && isConnected && (
        <Modal
          show={show}
          onHide={handleClose}
          size="xl"
          centered={true}
          dialogClassName="custom-modal"
          data-bs-theme="dark"
          fullscreen="lg-down"
        >
          <div className="container m-5">
            <div className="row ">
              {screen == 0 && (
                <>
                  <h1 className="text-center mb-3">
                    INVENTORY
                    <span
                      style={{
                        paddingRight: "4rem",
                        float: "right",
                        cursor: "pointer",
                      }}
                      onClick={handleClose}
                    >
                      <img src="/assets/img/closebutton.svg" alt="" />
                    </span>
                  </h1>
                  <div className="col-6 text-center d-flex flex-column justify-content-center">
                    <a href="#">
                      <img
                        src="/assets/img/planet.svg"
                        className="img-fluid"
                        alt=""
                        style={{ width: "40%" }}
                      />
                    </a>

                    <br />

                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <div
                        className="button-mint px-3"
                        onClick={() => {
                          isApproveEnoughForPlanet
                            ? mintPlanetSend()
                            : writeContract({
                                address: info.cosmotesContractAddress,
                                abi: tokenAbi,
                                functionName: "approve",
                                args: [
                                  info.planetContractAddress,
                                  globalState.planetFeeDecimal,
                                ],
                              });
                        }}
                      >
                        {isApproveEnoughForPlanet
                          ? "Mint New Planet"
                          : "Approve & Mint New Planet"}
                      </div>
                    </div>
                    <div
                      style={{ color: "white", fontFamily: "Poppins" }}
                      className="mt-1"
                    >
                      COST:{" "}
                      <span style={{ color: "#63A1FF" }}>
                        {globalState.planetFee}
                      </span>
                      <span style={{ color: "#7275CB" }}> COSMOTES</span>
                    </div>
                  </div>
                  <div className="col-6 text-center d-flex flex-column justify-content-center">
                    <a href="#">
                      <img
                        src="/assets/img/star.svg"
                        className="img-fluid"
                        alt=""
                        style={{ width: "40%" }}
                      />
                    </a>
                    <br />

                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <div
                        className="button-mint px-3"
                        onClick={() => {
                          isApproveEnoughForStar
                            ? mintStarSend()
                            : writeContract({
                                address: info.cosmotesContractAddress,
                                abi: tokenAbi,
                                functionName: "approve",
                                args: [
                                  info.starContractAddress,

                                  globalState.starFeeDecimal,
                                ],
                              });
                        }}
                      >
                        {isApproveEnoughForStar
                          ? "Mint New Star"
                          : "Approve & Mint New Star"}
                      </div>
                    </div>
                    <div
                      style={{ color: "white", fontFamily: "Poppins" }}
                      className="mt-1"
                    >
                      COST:{" "}
                      <span style={{ color: "#63A1FF" }}>
                        {globalState.starFee}
                      </span>
                      <span style={{ color: "#7275CB" }}> COSMOTES</span>
                    </div>
                  </div>
                </>
              )}
              {screen == 1 && (
                <>
                  <div className="col-12">
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        width: "100%",
                        flexDirection: "column",
                      }}
                    >
                      <h3 className="mt-3 small-header">
                        Your
                        {minting == "star" ? " Star" : " Planet"} is Minting
                      </h3>
                      <a>
                        <img
                          src="/assets/img/minting.gif"
                          alt=""
                          style={{ borderRadius: "8px" }}
                          width={"200px"}
                          className="img-fluid"
                        />
                      </a>
                    </div>
                  </div>
                </>
              )}

              {screen == 2 && minted && mintedMetadata && (
                <>
                  <div className="col-7 text-center">
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        width: "100%",
                        flexDirection: "column",
                      }}
                    >
                      <h3
                        style={{ fontFamily: "Poppins", color: "white" }}
                        className="mt-3 small-header"
                      >
                        YOU CREATED
                      </h3>
                      <a>
                        <img
                          src={mintedMetadata.image}
                          alt=""
                          style={{ borderRadius: "8px" }}
                          width="200px"
                          className="img-fluid"
                        />
                      </a>
                      <h2
                        style={{
                          fontFamily: "Poppins",
                          color: "white",
                          "&:hover": {
                            color: "#62A1FF",
                          },
                        }}
                        className="mt-3 small-header"
                      >
                        {mintedMetadata.name.toUpperCase()}
                      </h2>
                    </div>
                  </div>

                  <div className="col-5">
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-around",
                        alignItems: "center",
                        width: "100%",
                        flexDirection: "column",
                      }}
                    >
                      {Object.keys(mintedMetadata).map((key, index) => {
                        if (key != "id" && key != "name" && key != "image")
                          return (
                            <h5 className="mt-3 small-header" key={key}>
                              {key.toUpperCase()} :{" "}
                              {mintedMetadata[key].toUpperCase()}
                            </h5>
                          );
                      })}

                      <div
                        className="mt-5 p-3 button"
                        onClick={() => {
                          setScreen(0);
                          setMinted(null);
                          setMintedId(null);
                          setmintedMetadata(null);
                          mintToInventory();
                        }}
                      >
                        Go To Inventory
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
          {screen != 0 && (
            <div
              style={{
                right: "2.5rem",
                top: "2rem",
                position: "absolute",
                cursor: "pointer",
              }}
              onClick={handleClose}
            >
              <img src="/assets/img/closebutton.svg" alt="" />
            </div>
          )}
        </Modal>
      )}
    </>
  );
}

export default Minting;
