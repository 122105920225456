import React from "react";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import Minting from "./pages/Minting";
import "bootstrap/dist/css/bootstrap.min.css";
import { createConfig, WagmiProvider, http } from "wagmi";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { GlobalStateProvider, useGlobalState } from "./helpers/globalState";
import {
  ConnectKitProvider,
  ConnectKitButton,
  getDefaultConfig,
} from "connectkit";
import Index from "./pages/Index";
import SolarSystem from "./pages/Test";
import NewIndex from "./pages/NewIndex";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { sepolia } from "wagmi/chains";

const queryClient = new QueryClient();

export const config = createConfig(
  getDefaultConfig({
    // Required API Keys
    alchemyId: "4e9cc1697d0b299f35d388320a40b1d3", // or infuraId
    walletConnectProjectId: "cdd15b21fcfa9e1511476f66a7e30dae",

    // Required
    appName: "Your App Name",

    // Optional
    chains: [sepolia],
    appDescription: "Your App Description",
    appUrl: "https://uncw3b.com", // your app's url
    appIcon:
      "https://uncw3b.com/1694615642228-5321572a-477a-4243-9da1-e1782b8d4583.webp", // your app's icon, no bigger than 1024x1024px (max. 1MB)
    transports: {
      [sepolia.id]: http(),
    },
  })
);
const App = () => {
  return (
    <>
      <GlobalStateProvider>
        <ToastContainer
          position="top-center"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="dark"
        />
        <WagmiProvider config={config}>
          <QueryClientProvider client={queryClient}>
            <ConnectKitProvider theme="dark">
              <Router>
                <Routes>
                  <Route path="/" element={<NewIndex />} />
                </Routes>
              </Router>
            </ConnectKitProvider>
          </QueryClientProvider>
        </WagmiProvider>
      </GlobalStateProvider>
    </>
  );
};

export default App;
